import React, { Component } from "react"

export default class MoneyhubWidgetModal extends Component {
  loadScript = (callback) => {
    const existingScript = document.getElementById("mhWidgetScript")
    if (!existingScript) {
      const script = document.createElement("script")
      script.src =
        "https://s3.eu-west-1.amazonaws.com/widgets.moneyhub.co.uk/widgets.bundle.js"
      script.id = "mhWidgetScript"
      script.async = true
      document.body.appendChild(script)
      script.onload = () => {
        if (callback) callback()
      }
    }
    if (existingScript && callback) callback()
  }

  constructor(props) {
    super(props)
    const { isActive, deposit } = this.props
    console.log(isActive, isActive)
    this.state = { isActive: isActive }
  }
  componentDidMount() {
    // this.loadScript(
    //   "scriptUrl",
    //   alert(12)
    //   window.moneyhub.init({
    //     elementId: "root",
    //     type: "payment-single",
    //     widgetId: "171a8dbe-790d-4359-8eb7-40e866a508c0",
    //     params: {
    //       externalUserId: "external\\x2Duser\\x2Did",
    //       email: "email\\x40moneyhub.com",
    //       name: "Sarah\\x20Green",
    //       state: "client\\x2Dstate",
    //     },
    //   })
    //)
  }
  closeModalHandler(event) {
    var modal = document.querySelector(".modal-pwa")
    modal.classList.remove("is-active")
  }
  render() {
    const { isActive } = this.props
    return (
      <div className={`modal modal-pwa ${isActive ? "is-active" : ""} `}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Add to homescreen?</p>
            <button
              className="delete"
              aria-label="close"
              //onClick={this.closeModalHandler}
            />
          </header>
          <section className="modal-card-body">
            <div className="content">
              <div id="root"></div>
              <h5 className="sub-title is-5">Save this</h5>
              <ul>
                <li>Easy access to 365Saving</li>
                <li>Offline access</li>
                <li>
                  Optional reminders <br />
                  <small>(coming soon)</small>
                </li>
              </ul>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-primary button-pwa-install">
              Yes please
            </button>
            <button
              className="button is-text button-pwa-install-cancel"
              onClick={this.closeModalHandler}
            >
              No thank you
            </button>
          </footer>
        </div>
      </div>
    )
  }
}
