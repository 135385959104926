import localForage from "localforage";
import uuid from "uuid/v1";

const savingsTypes = () => {};
savingsTypes.ACCUMULATOR = "accumulator";

export default {
  checkSeed() {
    return localForage.getItem("Seeded");
  },
  getUserCurrency() {
    return localForage.getItem("UserCurrency").then(currencyCode => {
      return currencyCode || "GBP";
    });
  },
  setUserCurrency(currencyCode) {
    return localForage.setItem("UserCurrency", currencyCode);
  },
  addPlan(plan) {
    return localForage.getItem("UserPlans").then(userPlans => {
      let updatedPlans = userPlans || [];
      plan.id = uuid();
      plan.localDate = new Date();
      updatedPlans.push(plan);
      return localForage.setItem("UserPlans", updatedPlans);
    });
  },
  deleteUserPlan(id) {
    return this.getUserPlans().then(userPlans => {
      const removeIndex = userPlans
        .map(function(plan) {
          return plan.id;
        })
        .indexOf(id);
      ~removeIndex && userPlans.splice(removeIndex, 1);
      return localForage.setItem("UserPlans", userPlans);
    });
  },
  getUserPlans() {
    return localForage.getItem("UserPlans").then(userPlans => {
      return userPlans || [];
    });
    // order by start date (earliest first?)
  },
  getSavingTypes() {
    return localForage.getItem("SavingTypes");
  },
  seed() {
    return localForage.getItem("Seeded").then(isDbSeeded => {
      if (!isDbSeeded) {
        const savingTypes = [
          {
            name: "1p Saving Challenge",
            type: savingsTypes.ACCUMULATOR,
            days: 365,
            startAmount: 1,
            increment: 1
          },
          {
            name: "1p Saving Challenge (reverse)",
            type: savingsTypes.ACCUMULATOR,
            days: 365,
            startAmount: 365,
            increment: -1
          }
        ];
        localForage.setItem("Seeded", true);
        localForage.setItem("SavingTypes", savingTypes);
      }
    });
  }
};
