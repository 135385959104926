import React, { Component } from "react"
import posed, { PoseGroup } from "react-pose"
import UserPlan from "./UserPlan"
const cardConfig = {
  enter: { x: "0%" },
  exit: { x: "-150%" },
}

const Card = posed.div(cardConfig)

export default class UserPlans extends Component {
  render() {
    const userPlans = this.props.userPlans || []
    const {
      updateTotalSavedHandler,
      showSavingModalHandler,
      deletePlanHandler,
    } = this.props
    let currentDate = this.props.currentDate
    const plans = userPlans.map((userPlan, index) => {
      return (
        <Card className="card" key={userPlan.id}>
          <UserPlan
            updateTotalSavedHandler={updateTotalSavedHandler}
            userPlan={userPlan}
            currentDate={currentDate}
            deletePlanHandler={deletePlanHandler}
            showSavingModalHandler={showSavingModalHandler}
          />
        </Card>
      )
    })

    return (
      <div className="userplans section">
        <div className="plan-selector">
          <PoseGroup>
            {plans}
            <Card key="userplansnotification" className="notification is-dark">
              {/* <button className="delete" /> */}
              <strong>Missed some days?</strong>
              <br />
              Tap on the amount saved and enter your actual savings to see how
              much you need to add.
            </Card>
          </PoseGroup>
        </div>
      </div>
    )
  }
}
