import distanceInWords from "date-fns/distance_in_words"
import React, { Component, Fragment } from "react"
import posed from "react-pose"
import calculator from "./Calculator"
import { CurrencyCode, CurrencySymbol, Money } from "./Money"

const config = {
  visible: { fontSize: "100%", height: "100%", opacity: 1 },
  hidden: { fontSize: "0%", height: "0px", opacity: 0 },
}

const loadScript = (callback) => {
  const existingScript = document.getElementById("mhWidgetScript")
  if (!existingScript) {
    const script = document.createElement("script")
    script.src =
      "https://s3.eu-west-1.amazonaws.com/widgets.moneyhub.co.uk/widgets.bundle.js"
    script.id = "mhWidgetScript"
    script.async = true
    document.body.appendChild(script)
    script.onload = () => {
      if (callback) callback()
    }
  }
  if (existingScript && callback) callback()
}

const MakeItUpAmount = posed.div(config)

export default class UserPlan extends Component {
  constructor(props) {
    super(props)
    this.totalSavedRef = React.createRef()
    const { userPlan, currentDate } = this.props
    const totalRequiredAmount = calculator.getSavingAmountForDate(
      userPlan.startDate,
      currentDate,
      userPlan.plan.startAmount,
      userPlan.plan.increment
    )
    this.state = {
      actualSaved: totalRequiredAmount,
      showTotalSavedInput: false,
      originalTotalSaved: totalRequiredAmount,
      hideMakeUpMessage: true,
      makeItUpAmount: 0, // this is the amount to catch up, not including todays deposit
    }
  }

  totalSavedResetChangeHandler = (event) => {
    event.preventDefault()
    this.totalSavedRef.current.value = this.state.originalTotalSaved
    this.setState({
      actualSaved: this.state.originalTotalSaved,
      showTotalSavedInput: false,
    })

    this.totalSavedChangeHandler(event)
  }

  totalSavedBlurHandler = (event) => {
    event.preventDefault()
    if (this.state.makeItUpAmount <= 0) {
      this.setState({
        actualSaved: this.totalSavedRef.current.value,
        showTotalSavedInput: false,
      })
      this.totalSavedResetChangeHandler(event)
    }
  }

  totalSavedChangeHandler = (event) => {
    event.preventDefault()
    this.setState({
      makeItUpAmount:
        this.state.originalTotalSaved - this.totalSavedRef.current.value,
    })

    this.props.updateTotalSavedHandler(
      this.totalSavedRef.current.value - this.state.originalTotalSaved
    )
  }

  render() {
    const { userPlan, currentDate, deletePlanHandler, showSavingModalHandler } =
      this.props
    const {
      actualSaved,
      makeItUpAmount,
      originalTotalSaved,
      showTotalSavedInput,
    } = this.state
    const timeSince = distanceInWords(userPlan.startDate, currentDate)
    const todaysDeposit = calculator.getTodaysDeposit(
      userPlan.startDate,
      currentDate,
      userPlan.plan.startAmount,
      userPlan.plan.increment
    )

    return (
      <Fragment>
        <div className="card-footer">
          <div className="card-footer-item">{userPlan.plan.name}</div>
        </div>

        <progress
          className="progress is-primary is-small is-subtle"
          value={originalTotalSaved}
          max="667"
        >
          <Money value={originalTotalSaved} />
        </progress>
        <div className="card-content">
          <div className="content">
            <p className="title has-text-dark has-text-centered">
              Save <Money value={todaysDeposit} /> today{" "}
            </p>
            <div className="has-text-dark">
              <div className="container">
                <div className="level-item">
                  <button
                    className="button is-rounded is-moneyhub"
                    onClick={(e) => showSavingModalHandler(todaysDeposit, e)}
                  >
                    <span>Save with</span>
                    <img src="https://mh2.moneyhub.co.uk/tenant/94d46269b0c2b7ecce341c094fdb9a809cd0a49b43a4a8b03f3ec83458a786a0/assets/white-logo.svg"></img>
                  </button>
                </div>
                {/* <div
                  className={`level-item ${
                    showTotalSavedInput ? "is-hidden" : ""
                  }`}
                >
                  <button
                    className="button is-primary is-rounded"
                    onClick={() => {
                      this.setState({ showTotalSavedInput: true }, () =>
                        this.totalSavedRef.current.select()
                      )
                    }}
                  >
                    <Money value={actualSaved} /> saved{" "}
                    {originalTotalSaved > todaysDeposit && `in ${timeSince}`}
                  </button>
                </div> */}
                <div className={`${showTotalSavedInput ? "" : "is-hidden"}`}>
                  <label className="label is-size-7">Total Saved</label>
                  <div className="field has-addons">
                    <div className="control">
                      <a className="button is-static">
                        <CurrencySymbol />
                      </a>
                    </div>
                    <div className="control">
                      <input
                        name="totalSaved"
                        className="
                      saving-plan-total-input input is-primary"
                        defaultValue={originalTotalSaved}
                        type="number"
                        onBlur={this.totalSavedBlurHandler}
                        onMouseDown={(e) => e.currentTarget.select()}
                        onChange={this.totalSavedChangeHandler}
                        ref={this.totalSavedRef}
                      />
                    </div>
                    <div className="control">
                      <a className="button is-static">
                        <CurrencyCode />
                      </a>
                    </div>
                  </div>
                  <div className="field">
                    <MakeItUpAmount
                      className="help columns level is-mobile is-variable is-1"
                      pose={makeItUpAmount > 0 ? "visible" : "hidden"}
                    >
                      <span className="column catchup-column level-item is-narrow">
                        <a
                          onClick={this.totalSavedResetChangeHandler}
                          className="button is-primary is-small"
                        >
                          Reset
                        </a>
                      </span>
                      <span className="column has-text-dark is-size-7">
                        <div className="catchup-amount">
                          Catch up by saving{" "}
                          <strong>
                            <Money value={makeItUpAmount} />
                          </strong>
                        </div>
                      </span>
                    </MakeItUpAmount>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-header">
          <div className="card-header-title">
            <Money value="667.50" /> 🎯
          </div>
          <a
            onClick={(e) => deletePlanHandler(userPlan.id, e)}
            className="card-header-icon"
          >
            <span className="delete" />
          </a>
        </div>
      </Fragment>
    )
  }
}
