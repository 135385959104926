import Big from "big.js";
import React, { Component } from "react";
import { UserSettingsContext } from "./UserSettingsContext";

export class Money extends Component {
  formatInUserCurrency(value, language, currency) {
    const bigValue = Big(value || 0).toFixed(2);
    try {
      return Number(bigValue).toLocaleString(language, {
        style: "currency",
        currency: currency
      });
    } catch (e) {
      // browser doesnt support toLocaleString
      return `${bigValue} ${currency}`; // default
    }
  }

  render() {
    return (
      <UserSettingsContext.Consumer>
        {({ language, currency }) => (
          <React.Fragment>
            {this.formatInUserCurrency(this.props.value, language, currency)}
          </React.Fragment>
        )}
      </UserSettingsContext.Consumer>
    );
  }
}

export class CurrencySymbol extends Component {
  getCurrencySymbol(language, currency) {
    try {
      const valueWithCurrency = Number(0).toLocaleString(language, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      const valueWithoutCurrency = valueWithCurrency.replace(
        Number(0).toLocaleString(language, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }),
        ""
      );

      return valueWithoutCurrency;
    } catch (e) {
      return currency;
    }
  }

  render() {
    return (
      <UserSettingsContext.Consumer>
        {({ language, currency }) => (
          <React.Fragment>
            {this.getCurrencySymbol(language, currency)}
          </React.Fragment>
        )}
      </UserSettingsContext.Consumer>
    );
  }
}

export class CurrencyCode extends Component {
  render() {
    return (
      <UserSettingsContext.Consumer>
        {({ currency }) => <React.Fragment>{currency}</React.Fragment>}
      </UserSettingsContext.Consumer>
    );
  }
}
