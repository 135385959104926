import React from "react";

export const getBrowserLanguage = () => {
  if (navigator.languages !== undefined) {
    return navigator.languages[0];
  } else {
    return navigator.language;
  }
};

export const UserSettingsContext = React.createContext({
  currency: "GBP",
  name: "Bob",
  language: "",
  currencyChangeHandler: event => {}
});
