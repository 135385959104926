import React, { Component } from "react";

export default class PlanSelect extends Component {
  render() {
    const savingTypes = this.props.savingTypes || [];
    const { showFirstExperience } = this.props;
    const plans = savingTypes.map((savingType, index) => {
      return (
        <div
          key={`new-plan-${index}`}
          className="tile is-4 is-vertical is-parent"
        >
          <div
            className="tile is-child button box box-button is-large is-primary"
            onClick={e => this.props.setPlan(savingType, e)}
          >
            {savingType.name}
          </div>
        </div>
      );
    });

    return (
      <div className="plans section">
        {showFirstExperience && <h2 className="title is-2">Save every day!</h2>}
        <h4 className="subtitle is-4">Choose a Savings challenge!</h4>
        <div className="plan-selector tile is-ancestor">
          {plans || <span>No Plans</span>}
        </div>
      </div>
    );
  }
}
