import React, { Component } from "react";

export default class PWAModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  closeModalHandler() {
    var modal = document.querySelector(".modal-pwa");
    modal.classList.remove("is-active");
  }

  render() {
    return (
      <div className="modal modal-pwa">
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Add to homescreen?</p>
            <button
              className="delete"
              aria-label="close"
              onClick={this.closeModalHandler}
            />
          </header>
          <section className="modal-card-body">
            <div className="content">
              <h5 className="sub-title is-5">Extra features available</h5>
              <ul>
                <li>Easy access to 365Saving</li>
                <li>Offline access</li>
                <li>
                  Optional reminders <br />
                  <small>(coming soon)</small>
                </li>
              </ul>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-primary button-pwa-install">
              Yes please
            </button>
            <button
              className="button is-text button-pwa-install-cancel"
              onClick={this.closeModalHandler}
            >
              No thank you
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
