import Big from "big.js";
import differenceInCalendarDays from "date-fns/difference_in_calendar_days";
// backwards savings amounts
//const saveBackwardsAmountToday = (saveBackward(yearDay) / 100).toFixed(2);

export default {
  getSavingAmountForDate(
    fromDate,
    toDate,
    startAmount,
    incrementAmount,
    incrementDays = 1
  ) {
    const numberOfDays = differenceInCalendarDays(toDate, fromDate) + 1;
    return calculateSavingsForDays(
      numberOfDays,
      incrementAmount,
      incrementDays
    );
  },
  getTodaysDeposit(
    fromDate,
    toDate,
    startAmount,
    incrementAmount,
    incrementDays = 1
  ) {
    const numberOfDays = differenceInCalendarDays(toDate, fromDate) + 1;
    return Big(
      accumulateCalculator(numberOfDays, incrementAmount) / 100
    ).toFixed(2);
  },
  getFinalAmount(savingDuration, incrementAmount, incrementDays = 1) {
    return calculateSavingsForDays(savingDuration, incrementAmount);
  },
  getTotalSavingsForDate(userPlans, date) {
    return userPlans
      .reduce(
        (acc, userPlan) =>
          acc +
          parseFloat(
            this.getSavingAmountForDate(
              userPlan.startDate,
              date,
              userPlan.plan.startAmount,
              userPlan.plan.increment
            )
          ),
        0
      )
      .toFixed(2);
  }
};

const calculateSavingsForDays = (
  numberOfDays,
  incrementAmount,
  incrementDays = 1
) => {
  return (
    // complicated this
    //const Math.floor(numberOfDays / incrementDays)

    (
      [...Array(Math.floor(numberOfDays / incrementDays) + 1).keys()].reduce(
        (accumulator, currentDayNumber) => {
          return calculateSavingsForDaysReducer(
            accumulator,
            currentDayNumber,
            incrementAmount
          );
        }
      ) / 100
    ).toFixed(2)
  );
};

const calculateSavingsForDaysReducer = (
  accumulator,
  currentDayNumber,
  incrementAmount
) => {
  return accumulator + accumulateCalculator(currentDayNumber, incrementAmount);
};

const accumulateCalculator = (currentDayNumber, incrementAmount) => {
  if (incrementAmount > 0) {
    // accumulate forwards
    return currentDayNumber * incrementAmount;
  } else {
    // for a reverse accumulator need to start at last day number of year
    return 365 - (currentDayNumber + incrementAmount);
  }
};
