import format from "date-fns/format";
import React from "react";
import { Money } from "./Money";

const Navigation = props => {
  return (
    <div className="hero-head hero is-warning">
      <nav className="navbar">
        <div className="container">
          <div className="navbar-brand">
            <a
              className="navbar-item image is-128x128"
              onClick={props.homeHandler}
            >
              <img
                src="icon-128x128.png"
                width="128"
                height="128"
                alt="365 Saving Logo"
              />
            </a>
            <div className="navbar-item">
              <a onClick={props.homeHandler}>
                <p>YOUR SAVINGS</p>
                <h1 className="title">
                  <Money value={props.totalSavings} />
                </h1>
                <p className="subtitle is-5">
                  {format(props.currentDate, "Do MMMM")}
                </p>
              </a>
            </div>
          </div>
          <div id="navbarMenuHeroA" className="navbar-menu">
            <div className="navbar-end">
              <span className="navbar-item">
                <a
                  className="button is-primary is-inverted"
                  onClick={props.planSelectHandler}
                >
                  <span>New Savings Challenge</span>
                </a>
              </span>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
export default Navigation;
