import React, { Component } from "react";
import { UserSettingsContext } from "./UserSettingsContext";

const currencyList = [
  { code: "GBP", symbol: "£", flag: "🇬🇧" },
  { code: "USD", symbol: "$", flag: "🇺🇸" },
  { code: "EUR", symbol: "€", flag: "🇪🇺" },
  { code: "AUD", symbol: "$", flag: "🇦🇺" },
  { code: "JPY", symbol: "¥", flag: "🇯🇵" },
  { code: "THB", symbol: "฿", flag: "🇹🇭" }
];

export class CurrencySelector extends Component {
  render() {
    return (
      <UserSettingsContext.Consumer>
        {({ currency, changeCurrencyHandler }) => (
          <div className="currency-selector">
            <select
              value={
                currencyList.filter(({ code }) => {
                  return code === currency;
                }).length > 0
                  ? currency
                  : ""
              }
              onChange={changeCurrencyHandler}
            >
              {currencyList.map(({ code, symbol, flag }) => {
                return (
                  <option key={code} value={code}>
                    {flag} {symbol} {code}
                  </option>
                );
              })}
              <option key="other" value="">
                🏳️‍ Other
              </option>
            </select>
            <div className="currency-selector-line">
              {currencyList[0].flag} {currencyList[0].symbol}{" "}
              {currencyList[0].code}
            </div>
          </div>
        )}
      </UserSettingsContext.Consumer>
    );
  }
}
