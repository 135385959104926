import addDays from "date-fns/add_days";
import distanceInWordsStrict from "date-fns/distance_in_words_strict";
import format from "date-fns/format";
import isToday from "date-fns/is_today";
import isValid from "date-fns/is_valid";
import React, { Component } from "react";
import calculator from "./Calculator";
import { Money } from "./Money";

export default class PlanOptions extends Component {
  constructor(props) {
    super(props);
    // default date picker value
    this.state = {
      startDate: new Date(),
      savingDuration: 365,
      savingTotal: 66795
    };
  }
  updateStartDate = event => {
    event.preventDefault();
    if (isValid(new Date(this.startDate.value))) {
      this.setState({
        startDate: new Date(this.startDate.value)
      });
    }
  };

  saveUserPlan = event => {
    event.preventDefault();
    this.props.startSaving(new Date(this.startDate.value), event);
  };

  render() {
    const { startAmount, increment, homeEvent } = this.props;
    // work out how much you would have saved this year starting from the beginning of the year
    const savingDuration = distanceInWordsStrict(
      new Date(),
      addDays(new Date(), -this.state.savingDuration)
    );
    const startDate = format(this.state.startDate, "YYYY-MM-DD");

    const startingAmount = calculator.getSavingAmountForDate(
      startDate, // date picker date
      new Date(), // current date
      startAmount,
      increment
    );

    const todaysDeposit = calculator.getTodaysDeposit(
      startDate,
      new Date(),
      startAmount,
      increment
    );

    const totalSavings = calculator.getFinalAmount(
      this.state.savingDuration,
      increment
    );

    return (
      <section className="options section">
        <h2 className="title is-2">
          Save <Money value={totalSavings} /> in {savingDuration}!{" "}
          {/* from {startDateText} */}
        </h2>
        {isToday(startDate) ? (
          <p className="subtitle is-4">
            Today's deposit will be{" "}
            <strong>
              <Money value={todaysDeposit} />
            </strong>.
          </p>
        ) : (
          <div>
            <p>
              Your starting amount should be{" "}
              <strong>
                <Money value={startingAmount} />
              </strong>.{" "}
            </p>
            <p>You don't need to make another deposit till tomorrow.</p>
          </div>
        )}
        <div className="content">
          <a className="button is-light is-small is-invisible">
            <span className="icon">
              <i className="fab fa-down" />
            </span>
            <span>Options</span>
          </a>
          <div className="challenge-options">
            <div className="field">
              <label className="has-text-white">
                <strong>Start Date</strong>
              </label>
              <div className="control">
                <input
                  required="required"
                  className="input"
                  type="date"
                  name="startDate"
                  defaultValue={startDate}
                  onChange={this.updateStartDate}
                  ref={c => {
                    this.startDate = c;
                  }}
                />
              </div>
              <p className="help">
                This should be the date you started the challenge.
              </p>
            </div>
          </div>
        </div>
        <div className="buttons">
          <button
            className="button is-primary is-large"
            onClick={e => this.saveUserPlan(e)}
          >
            Start saving!
          </button>
          <button
            className="button button is-primary is-inverted is-large"
            onClick={homeEvent}
          >
            Cancel
          </button>
        </div>
      </section>
    );
  }
}
