import Big from "big.js"
import "bulma/css/bulma.css"
import isSameDay from "date-fns/is_same_day"
import React, { Component } from "react"
import ReactGA from "react-ga"
import posed, { PoseGroup } from "react-pose"
//import { version } from "../package.json"
import "./App.css"
import calculator from "./Calculator"
import { CurrencySelector } from "./CurrencySelector"
import data from "./Data"
import MoneyhubWidgetModal from "./MoneyhubWidgetModal"
import Navigation from "./Navigation"
import PlanOptions from "./PlanOptions"
import PlanSelect from "./PlanSelect"
import PWAModal from "./PWAModal"
import UserPlans from "./UserPlans"
import { getBrowserLanguage, UserSettingsContext } from "./UserSettingsContext"
const Item = posed.div({
  enter: { x: "0%", staggerChildren: 100 },
  exit: { x: "-100%" },
})

const SHOW_FIRST_EXPERIENCE = "showFirstExperience"
const SHOW_USER_PLANS = "showUserPlans"
const SHOW_PLAN_SELECT = "showPlanSelect"
const SHOW_PLAN_OPTIONS = "showPlanOptions"

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentView: SHOW_USER_PLANS,
      savingTypes: null,
      currentDate: new Date(),
      userPlans: [],
      currency: this.loadUserCurrency(),
      name: "Bert",
      language: getBrowserLanguage(),
      totalSavings: 0,
      moneyhubWidgetModalActive: false,
      todaysDeposit: 0,
    }
    this.loadData()
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000)
    //console.log("version", version)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  tick() {
    if (!isSameDay(this.state.currentDate, new Date())) {
      this.setState({
        //currentDate: addDays(this.state.currentDate, 1)
        currentDate: new Date(),
      })
    }
  }

  planSelectHandler = () => {
    this.setState({
      currentView: SHOW_PLAN_SELECT,
    })
  }

  planOptionsHandler = (plan) => {
    this.setState({
      currentView: SHOW_PLAN_OPTIONS,
      selectedPlan: plan,
    })
  }

  loadScript = (callback) => {
    const existingScript = document.getElementById("mhWidgetScript")
    if (!existingScript) {
      const script = document.createElement("script")
      script.src =
        "https://s3.eu-west-1.amazonaws.com/widgets.moneyhub.co.uk/widgets.bundle.js"
      script.id = "mhWidgetScript"
      script.async = true
      document.body.appendChild(script)
      script.onload = () => {
        if (callback) callback()
      }
    }
    if (existingScript && callback) callback()
  }

  showSavingModalHandler = (todaysDeposit) => {
    // this.setState({
    //   moneyhubWidgetModalActive: true,
    //   todaysDeposit: todaysDeposit,
    // })

    this.loadScript(() => {
      window.moneyhub.init({
        elementId: "root",
        type: "payment-single",
        widgetId: "171a8dbe-790d-4359-8eb7-40e866a508c0",
        params: {
          externalUserId: "external\\x2Duser\\x2Did",
          email: "email\\x40moneyhub.com",
          name: "Sarah\\x20Green",
          state: "client\\x2Dstate",
          amount: this.state.todaysDeposit,
        },
      })
    })
  }

  loadData = () => {
    data
      .checkSeed()
      .then((isSeeded) => {
        if (!isSeeded) {
          data.seed().then(() => {
            this.loadSavingTypes()
          })
        } else {
          this.loadSavingTypes()
        }
      })
      .then(this.loadUserPlans())
  }

  loadUserCurrency() {
    data.getUserCurrency().then((userCurrency) => {
      this.setState({
        currency: userCurrency,
      })
    })
  }

  loadUserPlans = () => {
    data.getUserPlans().then((userPlans) => {
      const totalSavings = calculator.getTotalSavingsForDate(
        userPlans,
        this.state.currentDate
      )
      this.expectedTotalSavings = totalSavings

      this.setState(
        {
          userPlans: userPlans,
          totalSavings: totalSavings,
        },
        this.homeHandler
      )
    })
  }

  homeHandler = () => {
    //const userPlans = this.state.userPlans;
    this.setState((prevState, props) => {
      return {
        currentView:
          prevState.userPlans && prevState.userPlans.length > 0
            ? SHOW_USER_PLANS
            : SHOW_FIRST_EXPERIENCE,
      }
    })
  }

  loadSavingTypes = () => {
    data.getSavingTypes().then((savingTypes) => {
      this.setState({
        savingTypes: savingTypes,
      })
    })
  }

  startSaving = (planStartDate) => {
    this.createSavingPlan(this.state.selectedPlan, planStartDate).then(
      (newPlan) => {
        this.loadUserPlans()
      }
    )

    ReactGA.event({
      category: "User",
      action: "Created a Plan",
    })
  }

  createSavingPlan = (selectedPlan, planStartDate) => {
    const plan = {
      plan: selectedPlan,
      startDate: planStartDate,
    }
    return data.addPlan(plan)
  }

  deletePlanHandler = (id) => {
    if (window.confirm("Delete this plan?")) {
      data.deleteUserPlan(id).then((newUserPlans) => {
        this.setState(
          {
            userPlans: newUserPlans,
          },
          this.loadUserPlans
        )
      })
    }
  }

  updateTotalSavedHandler = (changeAmount) => {
    this.setState({
      totalSavings: Big(this.expectedTotalSavings)
        .plus(changeAmount)
        .toFixed(2),
    })
  }

  changeCurrencyHandler = (event) => {
    const currencyCode =
      event.target.value !== ""
        ? event.target.value
        : prompt("Enter a currency code (NZD, BTC etc.)")

    this.setState({ currency: currencyCode }, () =>
      data.setUserCurrency(currencyCode)
    )
  }

  render() {
    ReactGA.initialize("UA-120354633-1", {
      testMode: process.env.NODE_ENV !== "production",
      debug: true,
    })

    const {
      currentView,
      currentDate,
      selectedPlan,
      savingTypes,
      userPlans,
      totalSavings,
      currency,
      language,
      moneyhubWidgetModalActive,
      todaysDeposit,
    } = this.state

    console.log("moneyhubWidgetModalActive", moneyhubWidgetModalActive)

    const addingSavingPlan = currentView !== SHOW_USER_PLANS
    return (
      <UserSettingsContext.Provider
        value={{
          currency: currency,
          language: language,
          changeCurrencyHandler: this.changeCurrencyHandler,
        }}
      >
        <section className="app-container hero is-medium is-365primary">
          <PWAModal />
          <MoneyhubWidgetModal
            isActive={moneyhubWidgetModalActive}
            deposit={todaysDeposit}
          />
          <Navigation
            homeHandler={this.homeHandler}
            planSelectHandler={this.planSelectHandler}
            addingSavingPlan={addingSavingPlan}
            currentDate={currentDate}
            totalSavings={totalSavings}
          />
          <CurrencySelector />
          <div className="hero hero-body saving-panels is-365primary">
            <PoseGroup>
              <Item key="itemAddButton">
                {(currentView === SHOW_FIRST_EXPERIENCE ||
                  currentView === SHOW_PLAN_SELECT) && (
                  <PlanSelect
                    setPlan={this.planOptionsHandler}
                    savingTypes={savingTypes}
                    showFirstExperience={currentView === SHOW_FIRST_EXPERIENCE}
                  />
                )}
              </Item>
              {currentView === SHOW_PLAN_OPTIONS && (
                <Item key="item4">
                  <PlanOptions
                    {...selectedPlan}
                    startSaving={this.startSaving}
                    homeEvent={this.homeHandler}
                  />
                </Item>
              )}
              {currentView === SHOW_USER_PLANS && (
                <Item key="item1">
                  <UserPlans
                    updateTotalSavedHandler={this.updateTotalSavedHandler}
                    deletePlanHandler={this.deletePlanHandler}
                    showSavingModalHandler={this.showSavingModalHandler}
                    setPlan={this.setPlan}
                    userPlans={userPlans}
                    currentDate={currentDate}
                  />
                </Item>
              )}
            </PoseGroup>
            {/* <small className="version">v{packageInfo.version}</small> */}
          </div>
          <nav
            id="navbarBottom"
            className={`navbar navbar-bottom is-fixed-bottom has-shadow is-white is-hidden-desktop ${
              addingSavingPlan && "is-invisible"
            }`}
          >
            <a
              className="navbar-bottom-button navbar-item button is-primary is-large"
              onClick={this.planSelectHandler}
            >
              <span>New Challenge</span>
            </a>
          </nav>
        </section>
      </UserSettingsContext.Provider>
    )
  }
}

export default App
